module controllers {
    export module master {

        interface IShipperListCtrlScope extends ng.IScope {


        }

        interface IShipperListParams extends ng.ui.IStateParamsService {
        }

        export class shipperListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'shipperService',
                '$state',
                '$stateParams',
                '$transitions',
                'statusService',
                'classificationValueService',
                'countryService'
            ];

            IsLoading: boolean = false;
            ShowGrid: boolean = false;

            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.master.IShipperDisplay>;
            searchAccordian: boolean = false;
            myHook: any;

            shipperSearch: interfaces.master.IShipperSearch = {
                CompanyStatusId:0
            };

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string;

            sortName: string;
            sortDirection: string;


            dropdownsObject = {
                ShipperStatusList: Array<interfaces.applicationcore.IDropdownModel>(),
            };

            statusList: interfaces.applicationcore.IDropdownModel[] = [];

            apiList: uiGrid.IGridApi;
            selected: uiGrid.IGridRow;

            items: string[];
            entity: string;
            currentEntity: interfaces.applicationcore.IEntity;

            selectedRowsArray: Array<interfaces.master.IShipperDisplay> = [];

            filterNames: string[] = 
            [   "EntityCode",
                "CompanyCode",
                "CompanyName",
                "CountryCode",
                "CompanyStatus",
                "Route",
                "SupplierName"
            ];

            filterList: interfaces.applicationcore.IKeyValue[] = [];



            constructor(private $scope: IShipperListCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private shipperService: interfaces.master.IShipperService,
                private $state: ng.ui.IStateService,
                private $stateParams: IShipperListParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private countryService: interfaces.applicationcore.ICountryService
            ) {

                this.shipperSearch = {
                    IsActive: true, CompanyStatusId: 0
                };

                this.ctrlName = 'Shipper List Controller';

                this.loadControls();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.MasterData.Shipper',
                    from: 'auth.MasterData.Shipper.**'
                }, () => {
                    this.loadShippers();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }

            loadShippers() {

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'shipper'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }
 
                        if(!this.shipperSearch.CompanyStatusId)
                        {
                            this.shipperSearch.CompanyStatusId=0;
                        }
                    
                        angular.forEach(this.shipperSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.shipperService.getList().query(params, (data) => {
                            this.IsLoading = false;
                            this.gvwList.data = data;
                            this.selectedRowsArray = [];

                            if (data[0]) {
                                this.gvwList.totalItems = data[0].NumberRecords;
                            } else {
                                this.gvwList.totalItems = 0;
                            }
                            this.$timeout(() => {
                                if (this.apiList) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });

                        }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });

            }


            loadControls() {
                let controlPromises = [
                    this.loadStatuses()
                ]

                this.$q.all(controlPromises).then((data) => {

                    this.ShowGrid = true;
                    this.DoSearch();
                });

            }


            search_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;
                this.DoSearch();
            }

            showAll_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;
                this.shipperSearch = {}
                this.filterList = [];

                if (this.apiList !== undefined) {
                    this.apiList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            DoSearch() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.shipperSearch, (n, key) => {

                    if (n) {
                        if (n["Id"]) {

                            searchObject.filters.push({ Name: key, Value: n.Id });

                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.apiList) {
                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                if (this.currentEntity)
                    this.entity = this.currentEntity.Code;

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    items: this.items,
                    entityCode: this.entity,
                    pageNumber: this.paginationOptions.pageNumber
                }

                this.shipperService.getList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = data;
                    this.selectedRowsArray = [];

                    if (data[0]) {
                        this.gvwList.totalItems = data[0].NumRecs;
                    } else {
                        this.gvwList.totalItems = 0;
                    }


                    this.$timeout(() => {
                        if (this.apiList) {
                            this.getFilters();
                            this.setFilters();
                        }
                    });

                    this.$timeout(() => {
                        if (this.selected) {
                            var index = _.findIndex(this.gvwList.data, (o) => { return o.Id === this.selected.entity.Id });
                            this.apiList.selection.selectRow(this.gvwList.data[index]);
                        }
                    });
                 


                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.shipperSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiList) {

                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    items: this.items,
                    entityCode: this.entity,
                }

                this.shipperService.getShippersListExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }



            loadStatuses() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CompanyStatus
                }, (resultList) => {
                    this.statusList = resultList;
                }).$promise;
            }


            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            loadCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }


            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiList.grid.getColumn(name).filters[0].term || this.apiList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.apiList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            ListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ListShortChange(gridApi) {
                this.DoSearch();
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if (!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if (keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }


            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.apiList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiList.core.on.rowsRendered(this.$scope, () => {

                    for (let selectedRow of this.selectedRowsArray) {

                        for (var row of this.gvwList.data) {

                            if (selectedRow.Id === row.Id) {
                                this.apiList.selection.selectRow(row);
                            }
                        }
                    }
                });

                this.apiList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selected = selected;

                    if (!selected.isSelected) {

                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                this.selectedRowsArray.splice(this.selectedRowsArray.indexOf(selectedRow), 1);
                            }
                        }
                    }
                    else {
                        var Exists: number = 0;
                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                Exists = 1;
                            }
                        }

                        if (Exists === 0) {
                            this.selectedRowsArray.push(selected.entity);
                        }
                    }
                });

                this.$timeout(() => {
                    if (this.selected)
                        this.apiList.selection.selectRow(this.selected.entity);
                });
            }


            gvwList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) 
                
                    this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div> 
                                <button type="button" ui-sref="auth.MasterData.Shipper.Update({ shpId: row.entity.Id })" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 80,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "CompanyCode",
                    displayName: "Shipper Code",
                    field: "CompanyCode",
                    width: 150,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "CompanyName",
                    displayName: "Company Name",
                    field: "CompanyName",
                    width: 320,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "CountryCode",
                    displayName: "Country",
                    field: "CountryCode",
                    width: 100,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "SupplierName",
                    displayName: "Supplier",
                    field: "SupplierName",
                    width: 320,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "Route",
                    displayName: "Route",
                    field: "Route",
                    width: 320,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                },
                {
                    name: "CompanyStatus",
                    displayName: "Status",
                    field: "CompanyStatus",
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    width: 130,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }

                ]
            };

            
            UploadFromExcel() {
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Upload from Excel</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="cominv.excelupload">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">

                                        <div class="row">
                                            <div class="row vertical-align">
                                                <div class="col-md-8">
                                                    <label class="fileUpload btn btn-primary file-upload-btn-primary" for="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit">
                                                        <span>Select File</span>
                                                        <input name="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit" class="upload" type="file" id="docFileToUpload" ng-model="uploadExcelCtrl.repositoryFile" base-sixty-four-input accept=".xlsx" />
                                                    </label>
                                                    <span>{{uploadExcelCtrl.repositoryFile.filename}}</span>
                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"  ng-disabled="!uploadExcelCtrl.repositoryFile || uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.upload()">Submit</button>
                                        <button class="btn btn-info" type="button" ng-disabled="uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class uploadExcelCtrl {

                        disableSubmit: boolean = false;
                        messages: interfaces.applicationcore.IMessage[];
                      
                        repositoryFile: interfaces.applicationcore.IRepositoryFile;
                        fileTemplate: interfaces.applicationcore.IDropdownModel;

                        fileTemplateList: interfaces.applicationcore.IDropdownModel[] = [];

                        viewFileTemplateRight: boolean = false;

                       productsExcelData: interfaces.master.IProductExcelData;

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private shipperService: interfaces.master.IShipperService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            public bsLoadingOverlayService,
                            private fileTemplateService: interfaces.master.IFileTemplateService,
                            private menuService: interfaces.applicationcore.IMenuService,                
                            private entityId: number
                        ) {

                        }

                        getViewFileTemplateRight() {
                            this.viewFileTemplateRight = false;
                            return this.menuService.getGTSConnectMenuItem(703).get((result: interfaces.applicationcore.IMenu) => {
                                if (result.name) {
                                    this.viewFileTemplateRight = true;
                                }
                            }).$promise;
                        }


                        designTemplateClick_click(){
                            var url = '/#!/MasterData/FileTemplates';
                            window.open(url, '_blank');
                        }

                 

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        upload() {
                            this.disableSubmit = true;
                            

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'cominv.excelupload'
                            },
                                () => {

                                    return this.shipperService.uploadSupplierShipperFromExcel().save(this.repositoryFile, (result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage) {
                                            this.$uibModalInstance.close(true);
                                        }

                                        this.$uibModalInstance.close(true);
                                    }, (errorResult) => {
                                        this.generalService.displayMessageHandler(errorResult.data);

                                        if (errorResult.data.Messages) {
                                            this.messages = [];
                                        }

                                        angular.forEach(errorResult.data.Messages, (o) => {
                                            angular.forEach(o.MessageItems, (n) => {
                                                this.messages.push(n);
                                            });
                                        });
                                        }).$promise;

                                });
                        }
                    },
                    controllerAs: "uploadExcelCtrl",
                    resolve: 
                    { 
                        entityId :  this.$rootScope.Entity.Id
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadShippers();
                    }
                });
            }


        }

        angular.module("app").controller("shipperListCtrl", controllers.master.shipperListCtrl);
    }
}